<template>
  <ErrorBoundary>
    <ul
      style="z-index: 100;"
      class="fixed mr-2 mt-1 right-0 top-0 font-semibold flex flex-col"
    >
      <li
        v-for="message in errors"
        :key="message"
        class="rounded-md mt-2 p-4 text-red-900 bg-white border-red-500 border-l-4 shadow-lg"
      >
        {{ message }}
      </li>
      <li
        v-for="message in success"
        :key="message"
        class="rounded-md mt-2 p-4 text-green-light bg-white border-green border-l-4 shadow-lg "
      >
        {{ message }}
      </li>
      <li
        v-for="message in info"
        :key="message"
        class="rounded-md mt-2 p-4 text-gray bg-white border-gray border-l-4 shadow-lg"
      >
        {{ message }}
      </li>
    </ul>
    <Loader v-if="processing" />
    <ReAuthenticationModal />
    <nav
      class="w-64 box-border flex flex-col items-center justify-between bg-green-darkest border-green-dark border-r-2 py-4"
      style="height: 100vh"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="flex flex-col items-center justify-between h-auto">
        <h2 class="text-3xl font-bold text-center w-full">
          {{ farmName }}
        </h2>
        <div
          v-if="unitsList.length > 1"
          @click="selectingUnit = !selectingUnit"
          class="flex flex-col tracking-wider relative items-center justify-center w-10/12 mt-6"
          :class="[filteredUnitsList.length > 0 ? 'cursor-pointer' : '']"
        >
          <span class="relative flex bg-white rounded-md px-3 items-center">
            <span class="text-yellow mr-3">
              <i class="fas fa-warehouse"></i>
            </span>
            <div class="items-center flex mt-1">
              <p class="mr-2 text-green">{{ t("unit") }}:</p>
              <p class="text-green-darkest font-semibold">
                {{ profile.selectedUnit }}
              </p>
              <span
                v-if="filteredUnitsList.length > 0"
                class="flex justify-center items-center text-yellow w-6 h-6 rounded-full"
              >
                <i class="fas fa-chevron-down"></i>
              </span>
            </div>
            <ul
              v-if="selectingUnit"
              class="absolute -ml-3 rounded-md top-8 divide-y-2 flex flex-col text-center bg-white font-semibold text-green-darkest shadow-md w-3/4 md:w-full z-40"
            >
              <li
                class="py-1 hover:bg-yellow hover:text-white rounded-md"
                v-for="unit in filteredUnitsList"
                :key="unit.id"
                :value="unit.data().unitName"
                @click="unitSelected(unit)"
              >
                {{ unit.data().unitName }}
              </li>
            </ul>
          </span>
        </div>
        <div
          class="flex flex-col justify-between items-center px-3 mt-3 w-full"
        >
          <div class=" w-48 text-green flex items-center justify-between">
            <span
              @click="decreasePeriod()"
              class="h-10 w-10 flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
            >
              <i class="fas fa-chevron-left"></i>
            </span>
            <p class="font-bold text-green">{{ t(`wageperiod`) }}</p>
            <span
              @click="increasePeriod()"
              class="h-10 w-10 flex justify-center items-center"
              :class="[
                isEndPeriod()
                  ? 'text-green'
                  : 'text-yellow hover:text-green transition-all cursor-pointer'
              ]"
            >
              <i class="fas fa-chevron-right"></i>
            </span>
          </div>
          <span class="flex w-full justify-center">
            <DatePicker
              v-model="start"
              class="text-green-darkest bg-white"
              :disabled="true"
              :small="true"
            />
            <p class="mx-3 text-green">-</p>
            <DatePicker
              v-model="end"
              class="text-green-darkest bg-white"
              :disabled="true"
              :small="true"
            />
          </span>
        </div>
      </div>
      <ul
        class=" w-full flex flex-col justify-evenly text-xl text-center z-30 text-white"
      >
        <router-link
          tag="li"
          class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full"
          active-class="from-yellow-dark to-yellow"
          :to="{ name: 'Dashboard' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-chart-bar"></i>
          </span>
          <p class=" text-sm ml-2">Dashboard</p>
        </router-link>
        <template v-if="selectedUnit !== undefined">
          <router-link
            v-if="selectedUnit.data().ownerRegistersTime || !isOwner()"
            tag="li"
            class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full"
            active-class="from-yellow-dark to-yellow"
            :to="{ name: 'TimeRegistration' }"
          >
            <span class="flex justify-center items-center">
              <i class="fas fa-stopwatch"></i>
            </span>
            <p class=" text-sm ml-2">{{ t(`timeRegistration.title`) }}</p>
          </router-link>
        </template>
        <router-link
          tag="li"
          class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full relative"
          active-class="from-yellow-dark to-yellow"
          :to="{ name: 'Requests' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-hand-holding-heart"></i>
          </span>
          <p class=" text-sm ml-2">{{ t(`requests`) }}</p>
          <span
            v-if="numberOfNewRequests !== 0 && (isOwner() || isLeader())"
            class="absolute -right-1 -top-1 w-6 h-6 bg-red-600 rounded-full border-2 border-red-500 border-solid shadow-md"
          >
            <p class="text-sm">{{ numberOfNewRequests }}</p></span
          >
        </router-link>
        <router-link
          tag="li"
          class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full"
          active-class="from-yellow-dark to-yellow"
          :to="{ name: 'Registrations' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-list"></i>
          </span>
          <p class=" text-sm ml-2">{{ t(`registrations`) }}</p>
        </router-link>
        <router-link
          tag="li"
          class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full"
          active-class="from-yellow-dark to-yellow"
          :to="{ name: 'Vacations' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-umbrella-beach"></i>
          </span>
          <p class=" text-sm ml-2">{{ t(`holidayVacations.title`) }}</p>
        </router-link>
        <router-link
          tag="li"
          v-if="isOwner()"
          class="w-full py-2 flex mb-2 items-center pl-6 bg-gradient-to-r hover:from-yellow-dark hover:to-yellow from-green to-green h-full"
          active-class="from-yellow-dark to-yellow"
          :to="{ name: 'Settings' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-cogs"></i>
          </span>
          <p class=" text-sm ml-2">{{ t(`settings.title`) }}</p>
        </router-link>
      </ul>
      <div class="w-full flex px-6">
        <router-link :to="{ name: 'UserProfile' }">
          <button class="open-main-nav flex flex-col items-center ">
            <span
              class="relative flex flex-shrink-0 justify-center items-center text-base transition duration-300 ease-in-out bg-green hover:bg-yellow rounded-full w-12 h-12 z-10"
            >
              <i class=" fas fa-user"></i>
              <span class="absolute pt-24 text-xs opacity-0 hover:opacity-100 ">
                {{ t(`profileSettings`) }}</span
              >
            </span>
          </button>
        </router-link>
        <span class="ml-4">
          <p>
            {{ t(`welcome`) }}
            <span v-if="profile !== null" class="text-yellow">{{
              profile.firstName
            }}</span>
          </p>
          <p class="text-green">
            {{ t(`position`) }}:
            <span v-if="profile !== null" class="">{{ t(getJobTitle()) }}</span>
          </p>
          <p class="flex mt-2 items-center" @click="signOutSubmit">
            <a href="#">{{ t(`signOut`) }}</a>
            <span
              class="flex flex-shrink-0 justify-center items-center w-8 h-8 ml-2 transition duration-300 ease-in-out cursor-pointer bg-green hover:bg-yellow rounded-full"
            >
              <i class="fas fa-sign-out-alt"></i>
            </span>
          </p>
        </span>
      </div>
      <router-link
        class=" w-full flex justify-center"
        :to="{ name: 'Dashboard' }"
      >
        <img src="@/assets/logo.svg" alt="Farmhelp logo" class="w-8/12" />
      </router-link>
      <Modal @closeModal="firstSignIn" :visible="firstSignIn">
        <h1 class="text-yellow text-3xl mt-4 font-bold tracking-wider">
          Velkommen til farmhelp!
        </h1>
        <span
          class=" text-green border-2 border-green rounded-full w-14 h-14 flex justify-center items-center text-xl my-4"
        >
          <i class="fas fa-key"></i>
        </span>
        <p class="text-center font-semibold text-white">
          Du kan ændre din kode nu,<br />
          eller gøre det senere i profil-indstillinger.
        </p>
        <div class="flex flex-col items-center mx-4 w-2/3 text-white">
          <div class="w-full">
            <Input
              class="w-full md:w-full mb-6"
              labelWidth="w-4/12"
              inputWidth="w-8/12"
              icon="fa-lock"
              type="password"
              placeholder="Ny kode . . ."
              v-model="password1"
            />
            <Input
              class="w-full md:w-full mb-6"
              labelWidth="w-4/12"
              inputWidth="w-8/12"
              icon="fa-lock"
              type="password"
              placeholder="Gentag kode . . ."
              v-model="password2"
            />
          </div>
          <p class=" ml-4 w-full text-center">
            Koden skal opfylde følgende krav:
          </p>
          <ul class="ml-8 text-white">
            <li class="flex">
              <span
                v-if="errorMinimumCharacters === false"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
              >
                <i class="fas fa-exclamation"></i>
              </span>
              <span
                v-if="errorMinimumCharacters"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
              >
                <i class="fas fa-check"></i>
              </span>
              Minimum 8 tegn
            </li>
            <li class="flex">
              <span
                v-if="errorContainsNumber === false"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
              >
                <i class="fas fa-exclamation"></i>
              </span>
              <span
                v-if="errorContainsNumber"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
              >
                <i class="fas fa-check"></i>
              </span>
              Indeholde et tal ( 0 - 9 )
            </li>
            <li class="flex">
              <span
                v-if="errorContainsLowercase === false"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
              >
                <i class="fas fa-exclamation"></i>
              </span>
              <span
                v-if="errorContainsLowercase"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
              >
                <i class="fas fa-check"></i>
              </span>
              Indeholde små bogstaver ( a - z )
            </li>
            <li class="flex">
              <span
                v-if="errorContainsUppercase === false"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
              >
                <i class="fas fa-exclamation"></i>
              </span>
              <span
                v-if="errorContainsUppercase"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
              >
                <i class="fas fa-check"></i>
              </span>
              Indeholde store bogstaver ( A - Z )
            </li>
            <li v-if="errorEqualPassword === false" class="flex">
              <span
                v-if="errorEqualPassword === false"
                class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
              >
                <i class="fas fa-exclamation"></i>
              </span>
              De to koder er ikke ens
            </li>
          </ul>
        </div>

        <Button
          label="Ændre kode"
          class="my-4 bg-gradient-to-r from-yellow-dark to-yellow"
          @click="changePassword(password1, password2)"
        />
      </Modal>
    </nav>
  </ErrorBoundary>
</template>

<script>
import { period } from "@/timeregistration/services/period";
import { farm } from "@/global/services/farm";
import { units } from "@/global/services/units";
import { errorLogging } from "@/global/services/errorLogging";
import { authentication } from "@/global/services/authentication";
import { requests } from "@/global/services/requests";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import ReAuthenticationModal from "@/global/components/re-authentication-modal";
import DatePicker from "@/components/DatePicker";
import { useRouter } from "vue-router";
import { ref, watchEffect, computed } from "vue";
import { isMobile } from "@/global/services/mixins";
import {
  validateContainsLowercase,
  validateContainsNumber,
  validateContainsUppercase,
  validateMinimumCharacters
} from "@/global/services/mixins";
import { employee } from "@/global/services/employee";

import ErrorBoundary from "@/global/components/errorBoundary";
import { useI18n } from "vue-i18n";

export default {
  name: "side-bar",
  components: {
    ErrorBoundary,
    Modal,
    Input,
    Button,
    Loader,
    DatePicker,
    ReAuthenticationModal
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          unit: "Unit"
        },
        dk: {
          unit: "Enhed"
        }
      }
    });
    const state = {
      employeeDoc: employee.get(true),
      farmName: computed(() => farm.getFarmName()),
      errorContainsLowercase: ref(undefined),
      errorContainsNumber: ref(undefined),
      errorContainsUppercase: ref(undefined),
      errorMinimumCharacters: ref(undefined),
      errorEqualPassword: ref(undefined),
      profile: authentication.getUserProfile(true),
      password1: ref(""),
      password2: ref(""),
      processing: ref(false),
      mobileMenu: ref(false),
      userMenu: ref(false),
      name: ref(""),
      firstSignIn: ref(false),
      selectingUnit: ref(false),
      selectedUnit: units.getSelectedUnit(true),
      unitsList: units.getUnitsList(true),
      filteredUnitsList: computed(() => {
        if (authentication.isAdmin()) {
          return state.unitsList.value;
        } else {
          return state.unitsList.value.filter(
            e =>
              e.data().unitName !== authentication.getUserSelectedUnit() &&
              state.employeeDoc.value.data().units.includes(e.id)
          );
        }
      }),
      requests: requests.getAllRequests(true),
      numberOfNewRequests: computed(() => {
        const newRequests = state.requests.value.filter(
          r => !r.data().accepted && !r.data().deleted && !r.data().declined
        );
        return newRequests.length;
      })
    };

    const functions = {
      decreasePeriod: period.decreasePeriod,
      increasePeriod: period.increasePeriod,
      isEndPeriod: period.isEndPeriod,
      isLeader: authentication.isLeader,
      getJobTitle: authentication.getJobTitle
    };

    watchEffect(() => {
      (authentication.isOwner() || authentication.isLeader()) &&
      units.getSelectedUnitEmployees().length !== 0 &&
      units.getSelectedUnitEmployees().length ===
        units.getSelectedUnitEmployeesLength()
        ? requests.updateAllRequests()
        : null;
    });

    document.addEventListener("processing", event => {
      state.processing.value = event.detail;
    });
    watchEffect(() => {
      if (state.password1.value !== "") {
        state.errorContainsLowercase.value = validateContainsLowercase(
          state.password1.value
        );
        state.errorContainsNumber.value = validateContainsNumber(
          state.password1.value
        );
        state.errorContainsUppercase.value = validateContainsUppercase(
          state.password1.value
        );
        state.errorMinimumCharacters.value = validateMinimumCharacters(
          state.password1.value
        );
      }
    });
    watchEffect(() => {
      if (state.password2.value !== "") {
        if (state.password2.value !== state.password1.value) {
          state.errorEqualPassword.value = false;
        } else {
          state.errorEqualPassword.value = true;
        }
      }
    });
    state.firstSignIn = computed(() => {
      if (state.profile.value !== null) {
        return state.profile.value.firstSignIn;
      } else {
        return false;
      }
    });

    const unitSelected = unit => {
      units.updateSelectedUnit(unit);
      state.selectingUnit = false;
    };

    const router = useRouter();

    const signOutSubmit = () => {
      authentication
        .signOut()
        .then(() => {
          router.push({ name: "Login" });
        })
        .catch(error => {
          console.log(error);
        });
    };

    return {
      t,
      errors: errorLogging.getErrors(),
      success: errorLogging.getSuccess(),
      info: errorLogging.getInfo(),
      ...state,
      ...period,
      ...functions,
      unitSelected,
      signOutSubmit,
      changePassword: authentication.changePassword,
      isMobile,
      isOwner: authentication.isOwner
    };
  }
};
</script>

<style scoped>
/* Main menu positionning */
.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: left;
  background: #00262d;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
  overflow: hidden;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 50;
  visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #00262d, #013f4b);
  transform-origin: top right;
  transform: skew(-14deg) translateX(120%);
  transition: all 0.275s 0.1s;
}

.main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 100%; /* Should be 100%, but we have a notice message :D */
  justify-content: center;
}

.main-nav li {
  transform: skew(0deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li a {
  transition: all 275ms 175ms;
}

/* Decoration */
.main-nav ul,
.main-nav li {
  list-style: none;
}
.main-nav a {
  display: block;
  padding: 12px 0;
  text-decoration: none;
  font-weight: bold;
}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {
  z-index: 40;
  background: none;
  border: 0;
  cursor: pointer;
}
.open-main-nav:focus {
  outline: none;
}
</style>
